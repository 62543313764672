$primary: #612DF5;
$primary-light: #F2EDFF;
$secondary: #FF0BC9;
$background: #FCFBFF;
$text-black: #2F3237;
$textBlack: #2F3237;
$textBlack2: #44474B;
//$guide: #006AD6;
$guide: $primary;
$error: #FF0D0D;
$maxWidth: 1080px;
$en-max-width: 1120px;
$breakPoint: 767px;

$headerHeight: 80px;
$bannerHeight: 0px;

$gold-gradation: linear-gradient(90deg, #FFE99B 0%, #FFAA5B 50%, #FFE99B 100%);

@font-face {
  font-family: 'Merry ma';
  src: url('/font/Merry-ma.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Lee Binna';
  src: url('/font/Lee-Binna.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.pc {
  @media (max-width: $breakPoint) {
    display: none;
  }
}

.mobile {
  display: none;
  @media (max-width: $breakPoint)  {
    display: unset;
  }
}

.hide {
  display: none !important;
}

@mixin responsive-padding {
  padding-left: 60px;
  padding-right: 60px;

  @media (max-width: $breakPoint) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@mixin scroll-style {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
      display: none;
  }
}

@mixin flex-row-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flex-col-center-center {
  @include flex-row-center-center;
  flex-direction: column;
}

@mixin icon($url, $width, $height) {
  width: $width;
  height: $height;
  background: url($url) $width $height;
}

@mixin icon-color($url, $width, $height, $color) {
  width: $width;
  height: $height;
  mask: url($url) $width $height;
  -webkit-mask: url($url) $width $height;
  background-color: $color;
}

@mixin modal-background {
  @include flex-row-center-center;
  position: fixed;
  inset: 0;
  background-color: rgba(82, 82, 82, 0.7);
}

@mixin success_modal {
  @include flex-col-center-center;
  width: 100%;
  background-color: white;
  font-size: 24px;
  font-weight: 700;
  color: $text-black;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
}
