@import '/src/styles/variables';

.wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -80px;
}
.container {
  @include flex-col-center-center;
  gap: 56px;
  position: relative;
  min-height: calc(100svh - $bannerHeight) !important;
  padding: 80px 0;
  overflow-y: scroll;
  @include scroll-style;
}


// login button
.login_btn {
  @include flex-row-center-center;
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 40px;
  border-radius: 4px;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 400;
  color: #585B5F;

  &::before {
    content: "";
    position: absolute;
    left: 25px;
  }
  &[id=google] {
    border: 1px solid #ECECEC;
    background-color: white;
    &::before {
      width: 18px;
      height: 18px;
      background: url("/images/user/common/google_logo.svg") 18px;
    }
  }
  &[id=naver] {
    background-color: #04CF5C;
    color: white;
    &::before {
      width: 16px;
      height: 14px;
      background: url("/images/user/common/naver_logo.svg") 16px 14px;
    }
  }
  &[id=kakao] {
    background-color: #FAE64D;
    &::before {
      width: 18px;
      height: 18px;
      background: url("/images/user/common/kakao_logo.svg") 18px;
    }
  }
  &[id=voli], &[id=wavedeck] {
    border: 1px solid #A081F9;
    &::before {
      width: 24px;
      height: 12px;
      background: url("/images/user/common/voli_logo.svg") 24px 12px;
    }
  }
}
/* ---------------------------------------------------------------------- */

// login 화면
.title_area {
  @include flex-col-center-center;
  gap: 16px;

  & img {
    cursor: pointer;
  }

  .welcome {
    margin-top: 16px;
    font-size: 1rem;
    line-height: 1.1875rem;
    font-weight: 400;
    color: #898989;
  }
}
.button_area {
  @include flex-col-center-center;
  gap: 24px;
  position: relative;
  color: #727486;
  & hr {
    width: 100%;
    max-width: 300px;
    height: 1px;
    border: none;
    background-color: #E0E0E0;
  }
}
.underline_btn {
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 500;
  text-decoration: underline;
  color: #727486;
}
.message {
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 400;
  white-space: nowrap;
  @media (max-width: $breakPoint) {
    white-space: inherit;
  }
  & a {
    text-decoration: underline;
  }
}
/* ---------------------------------------------------------------------- */

// 이메일로 시작하기
.email {
  @include flex-col-center-center;
  gap: 16px;
  width: 100%;
  max-width: 320px;
  margin-top: 16px;

  .bottom_guide {
    @include flex-col-center-center;
    gap: 24px;
    margin-top: 76px;
    color: #727486;
  }
}


// 비밀번호 입력
.password {
  @include flex-col-center-center;
  gap: 16px;
  width: 100%;
  max-width: 320px;

  .underline_btn {
    margin-top: 32px;
  }
}
